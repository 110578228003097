import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useFetching } from "@hooks/useFetch";

import PartService from "@api/PartService";

import Block from "@components/common/Block/Block";
import SwitchButton from "@components/common/SwitchButton/SwitchButton";
import ActionForm, { ActionFormRow, ActionFormInput, ActionFormTextArea, ActionFormButton } from "@components/common/ActionForm/ActionForm";

const CreatePartsPage: FC = () => {
  const router = useNavigate();

  const [part, setPart] = useState({
    name: "",
    brand: "",
    description: "",
    vendor: "",
    price_purchasing: 0,
    price_retail: 0,
    amount: 1,
    strict: false
  })

  const [fetchCreatePart] = useFetching(async () => {
    const response = await PartService.createPart(part);
    if (response.data.status) {
      router("/parts");
    }
  })

  function changePart(name: string, value: string | number | boolean) {
    setPart({
      ...part,
      [name]: value
    })
  }

  return (
    <section className="content">
      <Helmet>
        <title>Добавление запчастей - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Block style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
        <strong>Выгрузить из файла .xlsx</strong>
        <button className="style--positive">Выгрузить</button>
      </Block>
      <Block title="Добавить запчасть">
        <ActionForm>
          <ActionFormRow>
            <ActionFormInput 
              title="Наименование"
              actionProps={{
                type: "text",
                name: "name",
                value: part.name,
                onChange: (e: any) => changePart(e.target.name, e.target.value)
              }}
            />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormInput 
              title="Бренд"
              actionProps={{
                type: "text",
                name: "brand",
                value: part.brand,
                onChange: (e: any) => changePart(e.target.name, e.target.value)
              }}
            />
            <ActionFormInput 
              title="Артикул"
              actionProps={{
                name: "vendor",
                value: part.vendor,
                onChange: (e: any) => changePart(e.target.name, e.target.value)
              }}
            />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormTextArea 
              title="Описание"
              actionProps={{
                name: "description",
                value: part.description,
                onChange: (e: any) => changePart(e.target.name, e.target.value)
              }}
            />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormInput 
              title="Закупочная цена за шт."
              actionProps={{
                name: "price_purchasing",
                type: "number",
                value: part.price_purchasing,
                onChange: (e: any) => changePart(e.target.name, e.target.value)
              }}
            />
            <ActionFormInput 
              title="Розничная цена за шт."
              actionProps={{
                name: "price_retail",
                type: "number",
                value: part.price_retail,
                onChange: (e: any) => changePart(e.target.name, e.target.value)
              }}
            />
          </ActionFormRow>
          <ActionFormRow>
            <span>Учитывать количество</span>
            <SwitchButton value={part.strict} changeValue={(value: boolean) => changePart("strict", value)}/>
          </ActionFormRow>
          {
            part.strict &&
            <>
              <ActionFormRow>
                <ActionFormInput 
                  title="Количество в наличии"
                  actionProps={{
                    name: "amount",
                    value: part.amount,
                    onChange: (e: any) => changePart(e.target.name, e.target.value)
                  }}
                />
              </ActionFormRow>
              <span style={{fontSize: "80%", color: "var(--color-accent)"}}>Функция "учитывать количество" будет реагировать на остатки запчастей на складе. Если отключить функию, запчасть будет добавлена без учета остатков</span>
            </>
          }
          <ActionFormRow direction="end">
            <ActionFormButton 
              text="Добавить" 
              actionProps={{
                className: "style--positive",
                onClick: () => fetchCreatePart()
              }} 
            />
          </ActionFormRow>
        </ActionForm>
      </Block>
    </section>
  );
};

export default CreatePartsPage;