import React, { FC } from "react";
import InputMask from "react-input-mask";

import InputList from "../InputList/InputList";

import "./actionform.scss";

interface actionFormProps {
  children?: React.ReactNode
}

interface actionItemProps<T> {
  title?: string,
  actionProps?: React.HTMLAttributes<T>
}

interface actionFormInputProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  title?: string,
  actionProps?: React.InputHTMLAttributes<T>
}

interface actionFormInputMaskProps extends actionFormInputProps<HTMLInputElement> {
  mask: string,
  maskChar: string
}

interface actionFormRowProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: "start" | "end",
  children: React.ReactNode
}

interface actionFormButtonProps extends actionItemProps<HTMLButtonElement> {
  text: string
}

const ActionForm: FC<actionFormProps> = ({children}) => {
  return (
    <div className="action_form">
      {children}
    </div>
  );
};

export const ActionFormRow: FC<actionFormRowProps> = ({children, direction="start", ...props}) => {
  return (
    <div className={["action_form__row", `action_form__row--${direction}`].join(" ")} {...props}>
      {children}
    </div>
  );
}

export const ActionFormInput: FC<actionFormInputProps<HTMLInputElement>> = ({title, actionProps, ...props}) => {
  return (
    <div className="action_form__input" {...props}>
      <span>{title}</span>
      <input {...actionProps} />
    </div>
  );
}

export const ActionFormInputList: FC<any> = ({title, actionProps}) => {
  return (
    <div className="action_form__input">
      <span>{title}</span>
      <InputList {...actionProps} />
    </div>
  )
}

export const ActionFormInputMask: FC<actionFormInputMaskProps> = ({title, actionProps, mask, maskChar}) => {
  return (
    <div className="action_form__input">
      <span>{title}</span>
      <InputMask mask={mask} maskChar={maskChar} {...actionProps} />
    </div>
  );
}

export const ActionFormTextArea: FC<actionFormInputProps<HTMLTextAreaElement>> = ({title, actionProps}) => {
  return (
    <div className="action_form__textarea">
      <span>{title}</span>
      <textarea {...actionProps} />
    </div>
  );
}

export const ActionFormButton: FC<actionFormButtonProps> = ({text, actionProps}) => {
  return (
    <button {...actionProps}>{text}</button>
  );
}

export default ActionForm;