import AxiosInstance from "@lib/axiosInstanse";

export default class OrderService {
  public static async getOrders(query: any) {
    return await AxiosInstance.get("/order/get", {params: query});
  }
  public static async getCountOrders(query: any) {
    return await AxiosInstance.get("/order/count", {params: query});
  }
  public static async getOrderById(id: string) {
    return await AxiosInstance.get(`/order/get/${id}`);
  }
  public static async createOrder(orderData: any) {
    return await AxiosInstance.post("/order/create", orderData);
  }
  public static async updateOrder(id: string, orderData: any) {
    return await AxiosInstance.put(`/order/update`, {id, data: orderData});
  }
  public static async deleteOrder(id: string) {
    return await AxiosInstance.delete(`/order/delete/${id}`);
  }
}