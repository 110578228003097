import React, { FC } from 'react';

import classes from './block.module.scss';

interface blockProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  title?: string;
  children?: React.ReactNode;
}

const Block: FC<blockProps> = ({children, className, title, ...props}) => {
  return (
    <div className={[classes.block, className].join(" ")} {...props}>
      {
      title && 
      <div className={classes.block__title}>
        <strong>{title}</strong>
      </div>
      }
      {children}
    </div>
  );
};

export default Block;