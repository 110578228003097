import { FC, useReducer, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import AuthContext from "@contexts/AuthContext";

import { useFetching } from "@hooks/useFetch";

import OrderService from "@api/OrderService";
import UserService from "@api/UserService";

import Block from "@components/common/Block/Block";
import ActionForm, { ActionFormRow, ActionFormButton, ActionFormInput, ActionFormTextArea, ActionFormInputMask } from "../../components/common/ActionForm/ActionForm";

function reducer(state: any, action: any) {
  switch (action.type) {
    case "customer": {
      return {
        ...state,
        customer: {
          ...state.customer,
          [action.field]: action.value
        }
      }
    }
    case "services": {
      return {
        ...state,
        services: action.value
      }
    }
    case "master": {
      return {
        ...state,
        master: {
          ...state.master,
          [action.field]: action.value
        }
      }
    }
    case "any": {
      return {
        ...state,
        [action.field]: action.value
      }
    }
  }
}

const CreateOrderPage: FC = () => {
  const authContext = useContext(AuthContext);

  const router = useNavigate();

  const [users, setUsers] = useState<any>([]);
  const [order, dispatch] = useReducer(reducer, {
    name: "",
    serial_number: "",
    customer: {
      first_name: "",
      last_name: "",
      second_name: "",
      email: "",
      phone: ""
    },
    description: "",
    conclusion: "",
    equipment: "",
    note: "",
    guarantee: "",
    master: {
      id: "",
      name: !authContext.isAdmin ? authContext.user.firstName + " " + authContext.user.lastName : ""
    },
    start_date: "",
    expiration_date: "",
    status: "added",
    parts: [],
    services: [
      {
        name: "Диагностика",
        operation: "-",
        price: 800
      }
    ]
  })

  const [fetchCreateOrder] = useFetching(async (order: any) => {
    const response = await OrderService.createOrder(order);
    if (response.data.status) {
      router("/order/list");
    }
  })

  const [fechUsers] = useFetching(async () => {
    const response = await UserService.getUsers();
    if (response.data.status) {
      setUsers(response.data.response);
    }
  })

  function createOrder() {
    try {
      fetchCreateOrder({...order, master: {
        id: users.find((user: any) => user.firstName + " " + user.lastName === order.master.name).id,
        name: order.master.name
      }});
    } catch {
      alert("Не удалось создать заявку");
    }
  }

  useEffect(() => {
    fechUsers();
  }, []) // eslint-disable-line

  return (
    <section className="content">
      <Helmet>
        <title>Создание заявки - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Block title="Добавить заявку">
        <ActionForm>
          <ActionFormRow>
            <ActionFormInput
              title="Фамилия"
              actionProps={{
                type: "text",
                name: "last_name",
                value: order.customer.last_name,
                onChange: (e: any) => dispatch({
                  type: "customer",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
            <ActionFormInput
              title="Имя (организация)"
              actionProps={{
                type: "text",
                name: "first_name",
                value: order.customer.first_name,
                onChange: (e: any) => dispatch({
                  type: "customer",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
            <ActionFormInput
              title="Отчество"
              actionProps={{
                type: "text",
                name: "second_name",
                value: order.customer.second_name,
                onChange: (e: any) => dispatch({
                  type: "customer",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormInput
              title="Почта"
              actionProps={{
                type: "text",
                name: "email",
                value: order.customer.email,
                onChange: (e: any) => dispatch({
                  type: "customer",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
            <ActionFormInputMask
              title="Телефон"
              mask="+7 (999) 999-99-99"
              maskChar=" "
              actionProps={{
                type: "tel",
                name: "phone",
                value: order.customer.phone,
                onChange: (e: any) => dispatch({
                  type: "customer",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
          </ActionFormRow>
          <ActionFormInput
            title="Наименование"
            actionProps={{
              type: "text",
              name: "name",
              value: order.name,
              onChange: (e: any) => dispatch({
                type: "any",
                field: e.target.name,
                value: e.target.value
              })
            }}
          />
          <ActionFormInput
            title="Серийный номер"
            actionProps={{
              type: "text",
              name: "serial_number",
              value: order.serial_number,
              onChange: (e: any) => dispatch({
                type: "any",
                field: e.target.name,
                value: e.target.value
              })
            }}
          />
          <ActionFormInput
            title="Комплектация"
            actionProps={{
              type: "text",
              name: "equipment",
              value: order.equipment,
              onChange: (e: any) => dispatch({
                type: "any",
                field: e.target.name,
                value: e.target.value
              })
            }}
          />
          <ActionFormTextArea title="Описание проблемы" 
            actionProps={{ 
              name: "description", 
              value: order.description, 
              onChange: (e: any) => dispatch({ 
                type: "any", 
                field: e.target.name, 
                value: e.target.value 
              }) 
            }} 
          />
          <ActionFormTextArea title="Примечание" 
            actionProps={{ 
              name: "note", 
              value: order.note, 
              onChange: (e: any) => dispatch({ 
                type: "any", 
                field: e.target.name, 
                value: e.target.value 
              }) 
            }} 
          />
          <ActionFormInput
            title="Мастер"
            actionProps={{
              type: "text",
              name: "master",
              list: "masters",
              readOnly: !authContext.isAdmin,
              value: order.master.name,
              onChange: (e: any) => dispatch({
                type: "master",
                field: "name",
                value: e.target.value
              })
            }}
          />
          {
            authContext.isAdmin &&
            <datalist id="masters">
              {
                users.map((user: any) => (
                  <option key={user.id} value={user.firstName + " " + user.lastName} />
                ))
              }
            </datalist>
          }
          <ActionFormRow>
            <ActionFormInput
              title="Дата приёмки"
              actionProps={{
                type: "date",
                min: new Date().toISOString().split("T")[0],
                name: "start_date",
                value: order.start_date,
                onChange: (e: any) => dispatch({
                  type: "any",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
            <ActionFormInput
              title="Дата окончания работ"
              actionProps={{
                type: "date",
                min: new Date().toISOString().split("T")[0],
                name: "expiration_date",
                value: order.expiration_date,
                onChange: (e: any) => dispatch({
                  type: "any",
                  field: e.target.name,
                  value: e.target.value
                })
              }}
            />
          </ActionFormRow>
          <strong>Услуги</strong>
          {
            order.services.map((item: any, index: number) =>
              <ActionFormRow key={index} style={{alignItems: "flex-end"}}>
                <ActionFormInput title="Наименование" actionProps={{
                  type: "text",
                  value: item.name,
                  onChange: (e: any) => {
                    const newServices = [...order.services];
                    newServices[index].name = e.target.value;
                    dispatch({
                      type: "services",
                      value: newServices
                    });
                  }
                }} />
                <ActionFormButton 
                  text={item.operation}
                  actionProps={{
                    className: item.operation === "+" ? "style--positive" : "style--negative",
                    style: {padding: "10px 0px", width: "48px", height: "48px", textAlign: "center"},
                    onClick: () => {
                      const newServices = [...order.services];
                      newServices[index].operation = newServices[index].operation === "+" ? "-" : "+";
                      dispatch({
                        type: "services",
                        value: newServices
                      });
                    }
                  }} 
                />
                <ActionFormInput title="Цена" actionProps={{
                  type: "number",
                  value: item.price,
                  onChange: (e: any) => {
                    const newServices = [...order.services];
                    newServices[index].price = e.target.value;
                    dispatch({
                      type: "services",
                      value: newServices
                    });
                  }
                }} />
                <ActionFormButton text="Удалить" actionProps={{
                  className: "style--negative",
                  style: {
                    height: "48px"
                  },
                  onClick: () => {
                    const newServices = [...order.services];
                    newServices.splice(index, 1);
                    dispatch({
                      type: "services",
                      value: newServices
                    });
                  }
                }}/>
              </ActionFormRow>
            )
          }
          <ActionFormRow direction="end" style={{marginBottom: "35px"}}>
            <ActionFormButton
              text="Добавить"
              actionProps={{
                className: "style--positive",
                onClick: () => {
                  dispatch({
                    type: "services",
                    value: [...order.services, { name: "", operation: "+", price: 0 }]
                  });
                }
              }}
            />
          </ActionFormRow>
          <ActionFormRow direction="end">
            <ActionFormButton text="Создать" actionProps={{
              className: "style--positive",
              onClick: () => createOrder()
            }} />
          </ActionFormRow>
        </ActionForm>
      </Block>
    </section>
  );
};

export default CreateOrderPage;