import { FC, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useFetching } from "@hooks/useFetch";

import PartService from "@api/PartService";

import Block from "@components/common/Block/Block";
import ActionForm, { ActionFormRow, ActionFormInput, ActionFormTextArea, ActionFormButton } from "@components/common/ActionForm/ActionForm";
import Drawer from "@components/common/Drawer/Drawer";
import Dialog from "@components/common/Dialog/Dialog";
import SwitchButton from "@components/common/SwitchButton/SwitchButton";

import "./PartPage.scss";

const PartPage: FC = () => {
  const { id } = useParams();
  const router = useNavigate();

  const [amount, setAmount] = useState<number>(1);
  const [part, setPart] = useState<any>({});
  const [updatedPart, setUpdatedPart] = useState<any>({
    name: "",
    brand: "",
    description: "",
    vendor: "",
    price_purchasing: 0,
    price_retail: 0,
    amount: 1,
    strict: false
  });

  const [deletePartDrawer, setDeletePartDrawer] = useState(false);
  const [updatePartDrawer, setUpdatePartDrawer] = useState(false);

  const [fetchPart] = useFetching(async (id: string) => {
    const response = await PartService.getPartById(id);
    if (response.data.status) {
      setPart(response.data.response);
      setUpdatedPart(response.data.response);
    }
  })

  const [fetchUpdatePart] = useFetching(async (id: string, data: any) => {
    const response = await PartService.updatePart(id, data);
    if (response.data.status) {
      fetchPart(id);
      setUpdatePartDrawer(false);
    }
  })

  const [fetchDeletePart] = useFetching(async (id: string) => {
    const response = await PartService.deletePart(id);
    if (response.data.status) {
      router("/parts/list");
    }
  })

  function changeAmount(amount: number) {
    if (updatedPart.amount + amount < 0) return;
    setUpdatedPart({...updatedPart, amount: updatedPart.amount + amount});
    fetchUpdatePart(id, {...updatedPart, amount: updatedPart.amount + amount});
    fetchPart(id);
  }

  useEffect(() => {
    fetchPart(id);
  }, [id]) // eslint-disable-line
  return (
    <section className="content">
      <Helmet>
        <title>Запчасть {part.name || ""} - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Block className="part" title={"Запчасть " + part.name}>
        <div className="part__tags">
          <div className="part__tag style--freeze">
            <span>Артикул</span>
            <strong>{part.vendor === "" ? "Не указан" : part.vendor}</strong>
          </div>
          <div className="part__tag style--freeze">
            <span>Бренд</span>
            <strong>{part.brand === "" ? "Не указан" : part.brand}</strong>
          </div>
          {
            !part.strict &&
            <div className="part__tag style--translucent-15-warning">
              <span>Количество на складе</span>
              <strong>Без учёта</strong>
            </div>
          }
          {
            part.strict &&
            <div className={`part__tag ${part.amount <= 3 ? "style--translucent-15-negative" : "style--translucent-15-positive"}`}>
              <span>Количество на складе</span>
              <strong>{part.amount} шт.</strong>
            </div>
          }
        </div>
        <strong>Описание</strong>
        <p>{part.description}</p>
        <strong>Цены</strong>
        <span>Закупочная: {part.price_purchasing} ₽ за шт.</span>
        <span>Розничная: {part.price_retail} ₽ за шт.</span>
        {
          part.strict &&
          <div className="part__amount">
            <strong>Изменить</strong>
            <input type="number" value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
            <button className="style--positive" onClick={() => changeAmount(amount)}>+</button>
            <button className="style--negative" onClick={() => changeAmount(-amount)}>--</button>
          </div>
        }
        <ActionForm>
          <ActionFormRow direction="end">
            <ActionFormButton 
              text="Изменить" 
              actionProps={{
                className: "style--positive",
                onClick: () => setUpdatePartDrawer(true)
              }}
            />
            <ActionFormButton 
              text="Удалить" 
              actionProps={{
                className: "style--negative",
                onClick: () => setDeletePartDrawer(true)
              }}
            />
          </ActionFormRow>
        </ActionForm>
      </Block>
      <Drawer title={`Удалить запчасть "${part.name}"?`} state={deletePartDrawer} onClose={() => setDeletePartDrawer(false)}>
        <div className="center">
          <Dialog 
            title="Удаление запчасти"
            subtitle="Вы уверены, что хотите удалить запчасть?"
            onSuccess={() => fetchDeletePart(id)}
            onCancel={() => setDeletePartDrawer(false)}
          />
        </div>
      </Drawer>
      <Drawer title={`Изменить запчасть "${part.name}"?`} state={updatePartDrawer} onClose={() => setUpdatePartDrawer(false)}>
        <ActionForm>
          <ActionFormInput 
            title="Наименование" 
            actionProps={{
              value: updatedPart.name, 
              onChange: (e) => setUpdatedPart({...updatedPart, name: e.target.value})
            }} 
          />
          <ActionFormRow>
            <ActionFormInput 
              title="Бренд" 
              actionProps={{
                value: updatedPart.brand, 
                onChange: (e) => setUpdatedPart({...updatedPart, brand: e.target.value})
              }} 
            />
            <ActionFormInput 
              title="Артикул" 
              actionProps={{
                value: updatedPart.vendor, 
                onChange: (e) => setUpdatedPart({...updatedPart, vendor: e.target.value})
              }} 
            />
          </ActionFormRow>
          <ActionFormTextArea
            title="Описание" 
            actionProps={{
              value: updatedPart.description, 
              onChange: (e) => setUpdatedPart({...updatedPart, description: e.target.value})
            }} 
          />
          <ActionFormRow>
            <ActionFormInput 
              title="Закупочная цена" 
              actionProps={{
                value: updatedPart.price_purchasing, 
                onChange: (e) => setUpdatedPart({...updatedPart, price_purchasing: e.target.value})
              }} 
            />
            <ActionFormInput 
              title="Рознечная цена" 
              actionProps={{
                value: updatedPart.price_retail, 
                onChange: (e) => setUpdatedPart({...updatedPart, price_retail: e.target.value})
              }} 
            />
          </ActionFormRow>
          <ActionFormRow>
            <span>Учитывать количество</span>
            <SwitchButton value={updatedPart.strict} changeValue={(value: boolean) => setUpdatedPart({...updatedPart, strict: value})}/>
          </ActionFormRow>
          {
            updatedPart.strict &&
            <>
              <ActionFormRow>
                <ActionFormInput 
                  title="Количество в наличии"
                  actionProps={{
                    name: "amount",
                    value: updatedPart.amount,
                    onChange: (e) => setUpdatedPart({...updatedPart, amount: e.target.value})
                  }}
                />
              </ActionFormRow>
              <span style={{fontSize: "80%", color: "var(--color-accent)"}}>Функция "учитывать количество" будет реагировать на остатки запчастей на складе. Если отключить функию, запчасть будет добавлена без учета остатков</span>
            </>
          }
          <ActionFormRow>
            <ActionFormButton
                text="Изменить"
                actionProps={{
                  className: "style--positive",
                  onClick: () => fetchUpdatePart(id, updatedPart)
                }}
              />
            <ActionFormButton
              text="Отмена"
              actionProps={{
                className: "style--negative",
                onClick: () => setUpdatePartDrawer(false)
              }}
            />
          </ActionFormRow>
        </ActionForm>
      </Drawer>
    </section>
  );
};

export default PartPage;