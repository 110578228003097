import React, { FC } from "react";

import "./drawer.scss";

interface drawerProps {
  state: boolean
  onClose: () => void
  title?: string
  children?: React.ReactNode
}

const Drawer: FC<drawerProps> = ({state, onClose, title, children}) => {
  return (
    <div className={state ? "drawer drawer--active" : "drawer"} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} className="drawer__content">
        <div className="drawer__header">
          <strong>{title}</strong>
          <button onClick={onClose}>Закрыть</button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Drawer;