import { FC } from "react";
// import { Link } from "react-router-dom";

import UserMenu from "@components/UserMenu/UserMenu";

import "./header.scss";

const Header: FC = () => {
  return (
    <header className="header">
      <div className="header__content container">
        <div className="header__logo">
          <h2 className="style--accent-lite">{process.env.REACT_APP_NAME}</h2>
        </div>
        <nav className="header__nav">
          <a href={process.env.REACT_APP_MAIN_SITE} target="_blank" rel="noreferrer">Сайт магазина</a>
        </nav>
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;