import axios from "axios"; 

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  validateStatus: (status) => true
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
  return config;
});

instance.interceptors.response.use((config) => {return config}, async (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && error.config && !error.config._isRetry) {
    originalRequest._isRetry = true;
    try {
      const response = await instance.get(`${URL}/user/refresh`, {withCredentials: true});
      localStorage.setItem('accessToken', response.data.response.accessToken);
      return instance.request(originalRequest);
    } catch (e) {
      console.error('Пользователь не авторизован');
    }
  }
  throw error;
})

export default instance;