import React, { FC } from 'react';

import classes from './menu.module.scss';

interface menuProps {
  className?: string,
  buttons: {
    name: string,
    className?: string,
    icon?: string | React.ReactNode,
    onClick: () => void,
    border?: boolean,
    tag?: {
      title: string | number,
      className?: string
    }
  }[]
}

const Menu: FC<menuProps> = ({className, buttons}) => {
  return (
    <div className={[classes.menu, className].join(" ")}>
      {
        buttons.map((item, index) => 
          <React.Fragment key={index}>
            <button 
              key={index} 
              className={[classes.menu__button, item.className].join(" ")} 
              onClick={item.onClick}
            >
              {
                typeof item.icon === "string"
                ? <img src={item.icon} alt="" />
                : item.icon
              }
              <span>{item.name}</span>
              {item.tag && <strong className={item.tag.className}>{item.tag.title}</strong>}
            </button>
            {item.border && <div className={classes.menu__border} />}
          </React.Fragment>
        )
      }
    </div>
  );
};

export default Menu;