import React, { FC } from "react";

import CheckedIcon from "../../icons/CheckedIcon";
import UnCheckedIcon from "../../icons/UncheckedIcon";

import "./selectmenu.scss";

type valueType = {
  name: string,
  value: string
}

interface selectMenuProps {
  btns: valueType[],
  value: string,
  onChange: (value: valueType) => void
}

const SelectMenu: FC<selectMenuProps> = ({btns=[], value, onChange}) => {
  return (
    <div className="select_menu">
      {
        btns.map((item, index) => 
        <button key={index} className="style--hover-main-accent" onClick={() => onChange(item)}>
          {
            value === item.value
            ? <CheckedIcon className="style--fill-accent"/>
            : <UnCheckedIcon/>
          }
          <span>{item.name}</span>
        </button>
        )
      }
    </div>
  );
}

export default SelectMenu;
