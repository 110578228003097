import React, { FC } from "react";

import "./dialog.scss";

interface dialogProps {
  title: string
  subtitle?: string
  onSuccess?: () => void
  onCancel?: () => void
}

const Dialog: FC<dialogProps> = ({title, subtitle, onSuccess, onCancel}) => {
  return (
    <div className="dialog">
      <strong>{title}</strong>
      <span>{subtitle}</span>
      <div className="dialog__btns">
        <button className="style--negative" onClick={onCancel}>Отмена</button>
        <button className="style--positive" onClick={onSuccess}>Ок</button>
      </div>
    </div>
  );
};

export default Dialog;