import { FC, useEffect } from "react";

import AuthContext from "./contexts/AuthContext";
import MainContext from "@contexts/MainContext";

import { AuthStore } from "./store/AuthStore";
import { MainStore } from "./store/MainStore";

import AppRouter from "./router/AppRouter";

import { ReactNotifications } from "react-notifications-component";

import "moment/locale/ru";

import "./styles/normalize.scss";
import "./styles/scrollbar.scss";
import "./styles/fonts.scss";
import "./styles/colors.scss";
import "./styles/theming.scss";
import "./styles/style.scss";

import "react-notifications-component/dist/theme.css";

const App: FC = () => {
  const mainStore = MainStore();
  const authStore = AuthStore();

  useEffect(() => {
    authStore.refresh();
  }, []) // eslint-disable-line
  return (
    <MainContext.Provider value={mainStore}>
      <AuthContext.Provider value={authStore}>
        <ReactNotifications />
        <AppRouter />
      </AuthContext.Provider>
    </MainContext.Provider>
  );
}

export default App;