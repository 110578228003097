import { FC, useRef, useState } from "react";

import { useClickOver } from "../../../hooks/useClickOver";

import "./InputList.scss";

interface InputListProps {
  value: string,
  data: {
    value: string,
    label: string
  }[],
  onChange: (value: string, label: string) => void
}

const InputList: FC<InputListProps> = ({ value, data, onChange }) => {
  const container = useRef<HTMLDivElement>(null);

  const [dataOpen, setDataOpen] = useState<boolean>(false);
  const [lastLabel, setLastLabel] = useState<string>("");

  useClickOver(container, () => {
    setDataOpen(false);
  });

  return (
    <div ref={container} onClick={() => setDataOpen(true)} className={`input-list ${dataOpen ? "input-list--open" : ""}`}>
      <input type="text" value={value} onChange={(e) => onChange(e.target.value, "")} />
      <div className="input-list__label" style={{ display: lastLabel !== "" ? "block" : "none" }}>
        <span>{lastLabel}</span>
      </div>
      <div className="input-list__data" onClick={(e) => e.stopPropagation()}>
        {
          data.map((item) =>
            <button key={item.label} onClick={() => {
              onChange(item.value, item.label);
              setLastLabel(item.label);
              setDataOpen(false);
            }}>
              {item.value}
              <div className="input-list__label input-list__label--inactive">
                <span>{item.label}</span>
              </div>
            </button>
          )
        }
      </div>
    </div>
  );
};

export default InputList;