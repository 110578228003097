import { useState } from "react";

import { useFetching } from "@hooks/useFetch";

import UserService from "@api/UserService";

export function AuthStore() {
  const [user, setUser] = useState(null);

  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoad, setIsLoad] = useState(false);

  const [login] = useFetching(async (login: string, password: string) => {
    const response = await UserService.login(login, password);
    setIsLoad(true);

    if (response.data.status) {
      setUser(response.data.response);
      setIsAuth(true);
      setIsAdmin(response.data.response.isAdmin);
      localStorage.setItem("accessToken", response.data.response.accessToken);
    }
  })

  const [refresh] = useFetching(async () => {
    const response = await UserService.refresh();
    setIsLoad(true);

    if (response.data.status) {
      setUser(response.data.response);
      setIsAuth(true);
      setIsAdmin(response.data.response.isAdmin);
      localStorage.setItem("accessToken", response.data.response.accessToken);
    }
  })

  const [logout] = useFetching(async () => {
    const response = await UserService.logout();

    if (response.data.status) {
      setUser(null);
      setIsAuth(false);
      setIsAdmin(false);
      localStorage.removeItem("accessToken");
      window.location.reload();
    }
  });

  return {
    user,
    isAdmin,
    isAuth,
    isLoad,
    login,
    refresh,
    logout
  }
}