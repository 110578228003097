import { FC } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Block from "@components/common/Block/Block";

import "./OrderPreview.scss";

export interface IOrder {
  id?: string,
  name: string,
  serial_number: string,
  customer: {
    first_name: string,
    last_name: string,
    second_name: string,
    email: string,
    phone: string
  },
  description: string,
  conclusion: string,
  master: {
    id: string,
    name: string
  },
  start_date: string,
  expiration_date: string,
  status: "added" | "in_progress" | "completed" | "canceled",
  parts: {
    id: string,
    amount: number
  }[],
  price: {
    previously: number,
    finally: number
  },
}

interface orderPreviewProps {
  order: Partial<IOrder>;
}

const OrderPreview: FC<orderPreviewProps> = ({order}) => {
  const router = useNavigate();

  const statusNames = {
    added: "Добавлен",
    in_progress: "В работе",
    completed: "Завершён",
    canceled: "Отменён"
  }

  const statusColors = {
    added: "style--freeze",
    in_progress: "style--translucent-15-warning",
    completed: "style--translucent-15-positive",
    canceled: "style--translucent-15-negative"
  }

  const diffDateToColor: any = {
    "0": "style--translucent-15-negative",
    "1": "style--translucent-15-negative",
    "2": "style--translucent-15-warning",
  }

  function diffDate(date: string): number {
    const today = moment();
    return moment(date).diff(today, 'days');
  }

  return (
    <Block onClick={() => {router(`/order/id/${order.id}`)}} className="order-preview">
      <div className="order-preview__header">
        <strong>{order.name}</strong>
        <span>{moment(order.start_date).format('ll')} - {moment(order.expiration_date).format('ll')}</span>
      </div>
      <span className="style--freeze-lite">{order.description?.substring(0, 100)}</span>
      <div className="order-preview__tags">
        <div className={`order-preview__tag ${statusColors[order.status!] || ""}`}>
          <span>Статус</span>
          <strong>{statusNames[order.status!] || "Не определен"}</strong>
        </div>
        {
          (order.status === "added" || order.status === "in_progress") && (
            <div className={`order-preview__tag ${diffDateToColor[diffDate(order.expiration_date!).toString()] || (diffDate(order.expiration_date!) < 0 ? "style--translucent-15-negative" : "style--translucent-15-positive")}`}>
              <span>Дата окончания</span>
              {
                diffDate(order.expiration_date!) === 0
                ? <strong>Сегодня</strong>
                : <strong>{moment(order.expiration_date?.split("T")[0]).fromNow()}</strong>
              }
            </div>
          )
        }
        <div className="order-preview__tag style--freeze">
          <span>Серийный номер</span>
          <strong>{order.serial_number}</strong>
        </div>
        <div className="order-preview__tag style--freeze">
          <span>Мастер</span>
          <strong>{order.master?.name}</strong>
        </div>
        <div className="order-preview__tag style--freeze">
          <span>Заказчик</span>
          <strong>{order.customer?.first_name} {order.customer?.last_name}</strong>
        </div>
      </div>
    </Block>
  );
};

export default OrderPreview;