import { FC, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import AuthContext from "@contexts/AuthContext";

import Header from "@components/Header/Header";
import Split, { SplitBlock } from "@components/common/Split/Split";
import Menu from "@components/common/Menu/Menu";
import Block from "@components/common/Block/Block";

import HomeIcon from "@components/icons/HomeIcon";
import AddIcon from "@components/icons/AddIcon";
import ListIcon from "@components/icons/ListIcon";
import OrdersIcon from "@components/icons/OrdersIcon";
import ManufacturingIcon from "@components/icons/ManufacturingIcon";
import GroupIcon from "@components/icons/GroupIcon";

const PanelLayout: FC = () => {
  const router = useNavigate();
  const location = useLocation();

  const authContext = useContext(AuthContext);

  return (
    <>
      <Header />
      <main className="main">
        <Split className="containered">
          <SplitBlock type="fixed" width="350px">
            <Block>
              <Menu buttons={[
                {
                  name: "Главная",
                  className: `style--hover-accent ${location.pathname === "/" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                  icon: <HomeIcon />,
                  border: true,
                  onClick: () => router("/")
                },
                ...(authContext.isAdmin
                  ? [
                    {
                      name: "Добавить пользователя",
                      className: `style--hover-accent ${location.pathname === "/users/create" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                      icon: <AddIcon />,
                      onClick: () => router("/users/create")
                    },
                    {
                      name: "Пользователи",
                      className: `style--hover-accent ${location.pathname === "/users/list" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                      icon: <GroupIcon />,
                      border: true,
                      onClick: () => router("/users/list")
                    },
                  ]
                  : []
                ),
                {
                  name: "Добавить запчасть",
                  className: `style--hover-accent ${location.pathname === "/parts/create" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                  icon: <AddIcon />,
                  onClick: () => router("/parts/create")
                },
                {
                  name: "Запчасти",
                  className: `style--hover-accent ${location.pathname === "/parts/list" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                  icon: <ManufacturingIcon />,
                  border: true,
                  onClick: () => router("/parts/list")
                },
                {
                  name: "Добавить заявку",
                  className: `style--hover-accent ${location.pathname === "/order/create" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                  icon: <AddIcon />,
                  onClick: () => router("/order/create")
                },
                {
                  name: "Заявки в работе",
                  className: `style--hover-accent ${location.pathname === "/order/list" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                  icon: <ListIcon />,
                  onClick: () => router("/order/list")
                },
                {
                  name: "База заявок",
                  className: `style--hover-accent ${location.pathname === "/order/base" ? "style--translucent-15-accent style--fill-accent" : ""}`,
                  icon: <OrdersIcon />,
                  onClick: () => router("/order/base")
                }
              ]}/>
            </Block>
            <span 
              style={{fontSize: "80%", textAlign: "center", width: "100%", display: "block", marginTop: "10px"}}
              className="style--freeze-lite"
            >
              v. {process.env.REACT_APP_VERSION}
            </span>
          </SplitBlock>
          <SplitBlock type="grow">
            <section className="content">
              <Outlet />
            </section>
          </SplitBlock>
        </Split>
      </main>
    </>
  );
};

export default PanelLayout;