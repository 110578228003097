import { FC, useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment";

import AuthContext from "@contexts/AuthContext";

import { useFetching } from "@hooks/useFetch";

import OrderService from "@api/OrderService";
import PartService from "@api/PartService";

import Block from "@components/common/Block/Block";
import Drawer from "@components/common/Drawer/Drawer";
import ActionForm, { ActionFormRow, ActionFormInput, ActionFormInputList, ActionFormTextArea, ActionFormButton } from "@components/common/ActionForm/ActionForm";

import "./OrderPage.scss";

const OrderPage: FC = () => {
  const authContext = useContext(AuthContext);

  const { id } = useParams();
  const router = useNavigate();

  const timer = useRef<any>(null);

  const [cancelDrawer, setCancelDrawer] = useState<boolean>(false);
  const [expirationDateDrawer, setExpirationDateDrawer] = useState<boolean>(false);

  const [partSearch, setPartSearch] = useState<string>("");
  const [parts, setParts] = useState<any[]>([]);
  const [order, setOrder] = useState<any>({
    name: "",
    status: "",
    cancel_comment: "",
    expiration_date: ""
  });

  const statusNames: any = {
    added: "Добавлен",
    in_progress: "В работе",
    completed: "Завершён",
    canceled: "Отменён"
  }

  const statusColors: any = {
    added: "style--freeze",
    in_progress: "style--translucent-15-warning",
    completed: "style--translucent-15-positive",
    canceled: "style--translucent-15-negative"
  }

  const [fetchOrder] = useFetching(async (id: string) => {
    const response = await OrderService.getOrderById(id);
    if (response.data.status) {
      setOrder(response.data.response);
    }
  })

  const [fetchDeleteOrder] = useFetching(async (id: string) => {
    const response = await OrderService.deleteOrder(id);
    if (response.data.status) {
      router("/order/list");
    }
  })

  const [fetchChangeStatus] = useFetching(async (id: string, status: string) => {
    const response = await OrderService.updateOrder(id, { status });
    if (response.data.status) {
      setOrder({ ...order, status });
    }
  })

  const [fetchUpdateOrder] = useFetching(async (id: string, data: any) => {
    const response = await OrderService.updateOrder(id, data);
    if (response.data.status) {
      setOrder({ ...order, ...data });
    }
  })

  const [fetchParts] = useFetching(async (query: any) => {
    const response = await PartService.getParts(query);
    if (response.data.status) {
      setParts(response.data.response);
    }
  })

  const [fetchUpdatePart] = useFetching(async (id: string, data: any) => {
    const response = await PartService.updatePart(id, data);
    if (response.data.status) {
      fetchParts({ vendor: partSearch });
    }
  })

  const findPart = async (vendor: string) => {
    const response = await PartService.getParts({ name: vendor });

    if (response.data.status) {
      return response.data.response[0];
    }

    return null;
  }

  async function prepareParts(amountCheck: boolean = true): Promise<boolean> {
    const partsOriginal: any[] = [];

    for (const item of order.parts) {
      const part = await findPart(item.vendor);

      if (part) {
        partsOriginal.push(part);
      } else {
        alert(`Не удалось найти запчасть в базе: ${part.name} (${part.vendor})`);
        return false;
      }
    }

    for (const index in order.parts) {
      let part = order.parts[index];

      const newParts = [...order.parts];
      newParts[index as any].amount = parseInt(part.amount);
      newParts[index as any].price = parseInt(part.price);

      part.amount = parseInt(part.amount);
      part.price = parseInt(part.price);

      if (isNaN(part.amount) || part.amount <= 0) {
        alert(`Некорректное количество запчастей: ${part.name} (${part.vendor})`);
        return false;
      }

      if (isNaN(part.price) || part.price < 0) {
        alert(`Некорректная цена запчастей: ${part.name} (${part.vendor})`);
        return false;
      }

      setOrder({...order, parts: newParts});
    }

    for (const index in partsOriginal) {
      const part = partsOriginal[index];

      if (part.strict && amountCheck) {
        if (part.amount - order.parts[index].amount < 0) {
          alert(`Недостаточное количество запчастей: ${part.name} (${part.vendor})`);
          return false;
        }

        await fetchUpdatePart(part.id, {
          amount: part.amount - order.parts[index].amount
        });
      }

      const newParts = [...order.parts];
      newParts[index].id = part.id;
      setOrder({ ...order, parts: newParts });
    }

    return true;
  }

  function prepareServices(): boolean {
    const services = [...order.services];

    for (const index in services) {
      const price = parseInt(services[index].price);
      if (isNaN(price) || price < 0) {
        alert(`Некорректная цена услуги: ${services[index].name}`);
        return false;
      }

      services[index].price = price;
    }

    setOrder({ ...order, services });

    return true;
  }

  async function getDiagnosticDoc() {
    const isServicesReady = prepareServices();
    if (!isServicesReady) {
      return;
    }

    const isPartsReady = await prepareParts(false);
    if (!isPartsReady) {
      return;
    }

    const response = await OrderService.updateOrder(id!, order);
    if (response.data.status) {
      window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=diagnostic`, "_blank");
    }
  }

  async function backToWorkOrder() {
    const partsOriginal: any[] = [];

    for (const item of order.parts) {
      const part = await findPart(item.vendor);

      if (part) {
        partsOriginal.push(part);
      } else {
        alert(`Не удалось найти запчасть в базе: ${part.name} (${part.vendor})`);
        return false;
      }
    }

    for (const index in partsOriginal) {
      const origPart = partsOriginal[index];
      
      await fetchUpdatePart(origPart.id, {
        amount: origPart.amount + order.parts[index].amount
      });
    }

    fetchChangeStatus(order.id, "added")
  }
  
  async function completeOrder(id: string) {
    const isServicesReady = prepareServices();
    if (!isServicesReady) {
      return;
    }

    const isPartsReady = await prepareParts();
    if (isPartsReady) {
      fetchUpdateOrder(id, { ...order, status: "completed" });
    }
  }

  useEffect(() => {
    fetchOrder(id);
  }, [id]) // eslint-disable-line

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      fetchParts({ name: partSearch });
    }, 300)
    return () => {
      clearInterval(timer.current);
    }
  }, [partSearch]); // eslint-disable-line
  return (
    <section className="content">
      <Helmet>
        <title>Заявка {order.name} - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Block title={`Заявка ${order.name}`} className="order">
        <div className="order__tags">
          <div className={`order__tag ${statusColors[order.status] || ""}`}>
            <span>Статус</span>
            <strong>{statusNames[order.status] || "Неизвестен"}</strong>
          </div>
          <div className="order__tag style--freeze">
            <span>Мастер</span>
            <strong>{order.master?.name}</strong>
          </div>
          <div className="order__tag style--freeze">
            <span>Даты</span>
            <strong>{moment(order.start_date).format('ll')} - {moment(order.expiration_date).format('ll')}</strong>
          </div>
        </div>
        <strong>Заказчик</strong>
        <span>{order.customer?.first_name} {order.customer?.last_name} {order.customer?.second_name}</span>
        <span>{order.customer?.email}</span>
        <span>{order.customer?.phone}</span>
        <strong>Описание</strong>
        <p>{order.description}</p>
        {
          order.note !== "" && 
          <>
            <strong>Примечание</strong>
            <p>{order.note}</p>
          </>
        }
        {
          order.equipment !== "" && 
          <>
            <strong>Комплектация</strong>
            <p>{order.equipment}</p>
          </>
        }
        {
          order.status === "canceled" &&
          <>
            <strong>Причина отмены</strong>
            <p>{order.cancel_comment === "" ? "Не указано" : order.cancel_comment}</p>
          </>
        }
        {
          order.status === "completed" &&
          <>
            <strong>Заключение</strong>
            <p>{order.conclusion === "" ? "Не указано" : order.conclusion}</p>
            <strong>Комментарий мастера</strong>
            <p>{order.comment === "" ? "Не указано" : order.comment}</p>
            <strong>Запчасти</strong>
            {
              order.parts.length !== 0 
              ? <table>
                  <tbody>
                    <tr>
                      <td><b>Наименование</b></td>
                      <td><b>Кол-во</b></td>
                      <td><b>Цена за шт.</b></td>
                      <td><b>Цена</b></td>
                    </tr>
                    {
                      order.parts.map((part: any, index: number) => (
                        <tr key={index}>
                          <td>{part.name}</td>
                          <td>{part.amount}</td>
                          <td>{part.price} ₽</td>
                          <td>{part.price * part.amount} ₽</td>
                        </tr>
                      ))
                    }
                    <tr>
                      <td><b>Итого</b></td>
                      <td><b>{order.parts.reduce((a: number, b: any) => a + b.amount, 0)}</b></td>
                      <td></td>
                      <td><b>{order.parts.reduce((a: number, b: any) => a + (b.price * b.amount), 0)} ₽</b></td>
                    </tr>
                  </tbody>
                </table>
              : <p>Не указано</p>
            }
          </>
        }
        {
          (order.status === "added" || order.status === "completed") &&
          <>
            <strong>Услуги</strong>
            {
              order.services.length !== 0 
              ? <table>
                  <tbody>
                    <tr>
                      <td><b>Наименование</b></td>
                      <td><b>Цена</b></td>
                    </tr>
                    {
                      order.services.map((service: any, index: number) => (
                        <tr key={index}>
                          <td>{service.name}</td>
                          <td>{service.operation === "-" && "-"}{service.price} ₽</td>
                        </tr>
                      ))
                    }
                    <tr>
                      <td><b>Итого</b></td>
                      <td><b>{order.services.reduce((a: number, b: any) => a + (b.operation === "-" ? -b.price : b.price), 0)} ₽</b></td>
                    </tr>
                  </tbody>
                </table>
              : <p>Не указано</p>
            }
          </>
        }
        {
          (order.status === "in_progress" && (order.master?.id === authContext.user?.id || authContext.isAdmin)) &&
          <>
            <strong>Ведение заявки</strong>
            <ActionForm>
              <ActionFormTextArea 
                title="Заключение" 
                actionProps={{
                  value: order.conclusion,
                  onChange: (e: any) => {
                    setOrder({ ...order, conclusion: e.target.value })
                  }
                }}
              />
              <ActionFormTextArea 
                title="Комментарий мастера" 
                actionProps={{
                  value: order.comment,
                  onChange: (e: any) => {
                    setOrder({ ...order, comment: e.target.value })
                  }
                }}
              />
              <ActionFormInput 
                title="Исходя из вышеизложенного ремонт признаётся"
                actionProps={{
                  type: "text",
                  value: order.guarantee,
                  onChange: (e) => {
                    setOrder({...order, guarantee: e.target.value})
                  }
                }}    
              />
              <strong>Запчасти</strong>
              {
                order.parts.map((part: any, index: number) =>
                  <ActionFormRow key={index} style={{alignItems: "flex-end"}}>
                    <ActionFormInputList 
                      title="Наименование" 
                      actionProps={{
                        value: order.parts[index].name,
                        data: parts.map((part: any) => {
                          return {value: part.name, label: part.vendor}
                        }),
                        onChange: (value: string, label: string) => {
                          if (value !== "") {
                            setPartSearch(value);
                          }

                          const newParts = [...order.parts];
                          newParts[index].name = value;

                          if (label !== "") {
                            const part = parts.find((part: any) => part.vendor === label);
                            newParts[index].price = part.price_retail;
                            newParts[index].vendor = part.vendor;
                          }

                          setOrder({ ...order, parts: newParts });
                        }
                      }} 
                    />
                    <ActionFormInput
                      title="Количество"
                      style={{width: "150px", flex: "none"}}
                      actionProps={{
                        type: "number",
                        value: order.parts[index].amount,
                        onChange: (e: any) => {
                          const newParts = [...order.parts];
                          newParts[index].amount = e.target.value;
                          setOrder({ ...order, parts: newParts });
                        }
                      }}
                    />
                    <ActionFormInput
                      title="Цена за шт."
                      style={{width: "150px", flex: "none"}}
                      actionProps={{
                        type: "number",
                        value: order.parts[index].price,
                        onChange: (e: any) => {
                          const newParts = [...order.parts];
                          newParts[index].price = e.target.value;
                          setOrder({ ...order, parts: newParts });
                        }
                      }}
                    />
                    <ActionFormButton 
                      text="Удалить" 
                      actionProps={{
                        className: "style--negative",
                        style: {
                          height: "48px"
                        },
                        onClick: () => {
                          const newParts = [...order.parts];
                          newParts.splice(index, 1);
                          setOrder({ ...order, parts: newParts });
                        }
                      }} 
                    />
                  </ActionFormRow>
                )
              }
              <ActionFormRow direction="end">
                <ActionFormButton
                  text="Добавить"
                  actionProps={{
                    className: "style--positive",
                    onClick: () => {
                      setOrder({ ...order, parts: [...order.parts, { id: "", name: "", amount: 1, price: 0 }] })
                    }
                  }}
                />
              </ActionFormRow>
              <strong>Услуги</strong>
              {
                order.services.map((item: any, index: number) =>
                  <ActionFormRow key={index} style={{alignItems: "flex-end"}}>
                    <ActionFormInput 
                      title="Наименование" 
                      actionProps={{
                        type: "text",
                        value: item.name,
                        onChange: (e: any) => {
                          const newServices = [...order.services];
                          newServices[index].name = e.target.value;
                          setOrder({ ...order, services: newServices });
                        }
                    }}/>
                    <ActionFormButton 
                      text={item.operation}
                      actionProps={{
                        className: item.operation === "+" ? "style--positive" : "style--negative",
                        style: {padding: "10px 0px", width: "48px", height: "48px", textAlign: "center"},
                        onClick: () => {
                          const newServices = [...order.services];
                          newServices[index].operation = newServices[index].operation === "+" ? "-" : "+";
                          setOrder({ ...order, services: newServices });
                        }
                      }} 
                    />
                    <ActionFormInput 
                      title="Цена" 
                      style={{width: "150px", flex: "none"}} 
                      actionProps={{
                        type: "number",
                        value: item.price,
                        onChange: (e: any) => {
                          const newServices = [...order.services];
                          newServices[index].price = e.target.value;
                          setOrder({ ...order, services: newServices });
                        }
                    }}/>
                    <ActionFormButton 
                      text="Удалить" 
                      actionProps={{
                        className: "style--negative",
                        style: {
                          height: "48px"
                        },
                        onClick: () => {
                          const newServices = [...order.services];
                          newServices.splice(index, 1);
                          setOrder({ ...order, services: newServices });
                        }
                    }}/>
                  </ActionFormRow>
                )
              }
              <ActionFormRow direction="end" style={{marginBottom: "35px"}}>
                <ActionFormButton
                  text="Добавить"
                  actionProps={{
                    className: "style--positive",
                    onClick: () => {
                      setOrder({ ...order, services: [...order.services, { name: "", price: 0 }] })
                    }
                  }}
                />
              </ActionFormRow>
            </ActionForm>
          </>
        }
        {
          (order.master?.id === authContext.user?.id || authContext.isAdmin) &&
          <ActionForm>
            <ActionFormRow direction="end">
              {
                order.status === "completed" &&
                <>
                  <ActionFormButton 
                    text="Акт приёмки" 
                    actionProps={{
                      className: "style--positive",
                      onClick: () => {
                        window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=accept`, "_blank");
                      }
                    }} 
                  />
                  <ActionFormButton 
                    text="Акт диагностики" 
                    actionProps={{
                      className: "style--positive",
                      onClick: () => {
                        window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=diagnostic`, "_blank");
                      }
                    }} 
                  />
                  <ActionFormButton 
                    text="Акт работы" 
                    actionProps={{
                      className: "style--positive",
                      onClick: () => {
                        window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=work`, "_blank");
                      }
                    }} 
                  />
                </>
              }
              {
                order.status === "in_progress" && (
                  <>
                    <ActionFormButton 
                      text="Завершить" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => completeOrder(order.id)
                      }} 
                    />
                    <ActionFormButton 
                      text="Акт диагностики" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => {
                          getDiagnosticDoc();
                        }
                      }} 
                    />
                  </>
                )
              }
              {
                order.status === "added" && (
                  <>
                    <ActionFormButton 
                      text="В работу" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => fetchChangeStatus(order.id, "in_progress")
                      }} 
                    />
                    <ActionFormButton 
                      text="Акт приёмки" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => {
                          window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=accept`, "_blank");
                        }
                      }} 
                    />
                  </>
                )
              }
              {
                (order.status === "added" || order.status === "in_progress") && (
                  <>
                    <ActionFormButton 
                      text="Изменить конечную дату" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => setExpirationDateDrawer(true)
                      }} 
                    />
                    <ActionFormButton 
                      text="Отменить" 
                      actionProps={{
                        className: "style--negative",
                        onClick: () => setCancelDrawer(true)
                      }} 
                    />
                  </>
                )
              }
              {
                order.status === "canceled" && (
                  <>  
                    <ActionFormButton 
                      text="Акт приёмки" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => {
                          window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=accept`, "_blank");
                        }
                      }} 
                    />
                    <ActionFormButton 
                      text="Акт диагностики" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => {
                          window.open(`${process.env.REACT_APP_API_URL}/order/get/doc/${order.id}?type=diagnostic`, "_blank");
                        }
                      }} 
                    />
                  </>
                )
              }
              {
                ((order.status === "completed" || order.status === "canceled") && authContext.isAdmin) && (
                  <>
                    <ActionFormButton 
                      text="Откатить" 
                      actionProps={{
                        className: "style--positive",
                        onClick: () => backToWorkOrder()
                      }} 
                    />
                    <ActionFormButton 
                      text="Удалить" 
                      actionProps={{
                        className: "style--negative",
                        onClick: () => fetchDeleteOrder(order.id)
                      }} 
                    />
                  </>
                )
              }
            </ActionFormRow>
          </ActionForm>
        }
      </Block>
      <Drawer state={cancelDrawer} onClose={() => setCancelDrawer(false)} title="Отмена заявки">
        <ActionForm>
          <ActionFormInput 
            title="Комментарий" 
            actionProps={{
              type: "text", 
              value: order.cancel_comment, 
              onChange: (e: any) => {
                setOrder({ ...order, cancel_comment: e.target.value })
              }
            }}
          />
          <ActionFormRow direction="end">
            <ActionFormButton 
              text="Отменить" 
              actionProps={{
                className: "style--negative",
                onClick: () => setCancelDrawer(false)
              }} 
            />
            <ActionFormButton 
              text="Подтвердить" 
              actionProps={{
                className: "style--positive",
                onClick: () => {
                  fetchUpdateOrder(order.id, { status: "canceled", cancel_comment: order.cancel_comment })
                  setCancelDrawer(false)
                }
              }} 
            />
          </ActionFormRow>
        </ActionForm>
      </Drawer>
      <Drawer state={expirationDateDrawer} onClose={() => setExpirationDateDrawer(false)} title="Изменить конечную дату">
        <ActionForm>
          <ActionFormInput 
            title="Дата" 
            actionProps={{
              type: "date", 
              value: order.expiration_date, 
              min: new Date().toISOString().split("T")[0],
              onChange: (e: any) => {
                setOrder({ ...order, expiration_date: e.target.value })
              }
            }}
          />
          <ActionFormRow direction="end">
            <ActionFormButton 
              text="Отменить" 
              actionProps={{
                className: "style--negative",
                onClick: () => setExpirationDateDrawer(false)
              }} 
            />
            <ActionFormButton 
              text="Подтвердить" 
              actionProps={{
                className: "style--positive",
                onClick: () => {
                  fetchUpdateOrder(order.id, { expiration_date: order.expiration_date })
                  setExpirationDateDrawer(false)
                }
              }} 
            />
          </ActionFormRow>
        </ActionForm>
      </Drawer>
    </section>
  );
};

export default OrderPage;