import { FC, useState } from "react";
import { Helmet } from "react-helmet";

import { useFetching } from "@hooks/useFetch";

import OrderService from "@api/OrderService";

import OrderPreview from "@components/OrderPreview/OrderPreview";
import FilterSearch from "@components/FilterSearch/FilterSearch";

const OrderBasePage: FC = () => {
  const [orders, setOrders] = useState<any>([]);

  const [fetchOrders] = useFetching(async (query: any) => {
    const response = await OrderService.getOrders({query});
    if (response.data.status) {
      setOrders(response.data.response);
    }
  })

  function onSearchChange(query: any) {
    fetchOrders(query);
  }

  return (
    <section className="content">
      <Helmet>
        <title>База заявок - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <FilterSearch onChange={onSearchChange} filters={[
        {
          id: "status",
          name: "Статус",
          values: [
            {
              name: "все",
              value: "inactive"
            },
            {
              name: "завершён",
              value: "completed"
            },
            {
              name: "отменён",
              value: "canceled"
            },
          ]
        }
      ]} />
      {
        orders.length !== 0
        ?
        orders.map((order: any) => 
          <OrderPreview key={order.id} order={order} />
        )
        : <div style={{width: "100%", padding: "20px", textAlign: "center"}}>Ничего не найдено</div>
      }
    </section>
  );
};

export default OrderBasePage;