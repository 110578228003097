import { FC, useContext } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"

import AuthContext from "../contexts/AuthContext";

import { publicPaths, privatePaths, adminPaths } from "./paths";

const AppRouter: FC = () => {
  const authContext = useContext(AuthContext);

  const router = createBrowserRouter([
    {
      errorElement: <Navigate to="/login"></Navigate>
    },
    ...(authContext.isAuth 
      ? [...privatePaths, ...publicPaths] 
      : publicPaths
    ),
    ...(authContext.isAdmin ? adminPaths : [])
  ]);

  return (
    <>
      {
        authContext.isLoad
        ? <RouterProvider router={router} />
        : <div className="center">Загрузка...</div>
      }
    </>
  );
};

export default AppRouter;