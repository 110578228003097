import { useState } from "react";

export const useFetching = (callback: any): [(...args: any) => Promise<void>, boolean, string] => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  async function fetching(...args: any) {
    try {
      setIsLoading(true);
      await callback(...args);
    }
    catch (e: any) {
      console.log(e)
      setError(e.message);
    }
    finally {
      setIsLoading(false);
    }
  }

  return [fetching, isLoading, error];
}