import { FC, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import AuthContext from "@contexts/AuthContext";

import "./loginpage.scss";

const LoginPage: FC = () => {
  const router = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const authContext = useContext(AuthContext);

  async function tryLogin() {
    await authContext.login(login, password);
  }

  useEffect(() => {
    if (authContext.isAuth) {
      router("/");
    }
  }, [authContext]) // eslint-disable-line

  return (
    <section className="content">
      <Helmet>
        <title>Войдите в панель - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <div className="center">
        <div className="login">
          <img src={process.env.REACT_APP_URL + "/common/favicon.svg"} alt="" />
          <h1>Войдите в панель</h1>
          <input type="text" placeholder="example@tekh.ru" value={login} onChange={(e) => setLogin(e.target.value)} />
          <input type="password" placeholder="*********" value={password} onChange={(e) => setPassword(e.target.value)} />
          <div className="login__btns">
            <button onClick={() => tryLogin()}>Войти</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;