import AxiosInstance from "@lib/axiosInstanse";

export default class UserService {
  public static async login(login: string, password: string) {
    return await AxiosInstance.post("/user/login", {login, password});
  }
  public static async refresh() {
    return await AxiosInstance.put("/user/refresh");
  }
  public static async logout() {
    return await AxiosInstance.delete("/user/logout");
  }
  public static async getUsers() {
    return await AxiosInstance.get("/user/get");
  }
  public static admin = class {
    public static async register(data: any) {
      return await AxiosInstance.post("/user/register", data);
    }
    public static async updateUser(id: string, userData: any) {
      return await AxiosInstance.put(`/user/update`, {id: id, data: userData});
    }
    public static async updatePassword(id: string, password: string) {
      return await AxiosInstance.put(`/user/changepassword`, {id, password});
    }
    public static async deleteUser(id: string) {
      return await AxiosInstance.delete(`/user/delete/${id}`);
    }
  }
}