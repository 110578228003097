import { RouteObject } from "react-router-dom";

// layouts
import PanelLayout from "@layouts/PanelLayout/PanelLayout";
import CenterLayout from "@layouts/CenterLayout/CenterLayout";

// pages
import IndexPage from "@pages/IndexPage/IndexPage";
import LoginPage from "@pages/LoginPage/LoginPage";
import CreateOrderPage from "@pages/CreateOrderPage/CreateOrderPage";
import OrderListPage from "@pages/OrderListPage/OrderListPage";
import OrderBasePage from "@pages/OrderBasePage/OrderBasePage";
import OrderPage from "@pages/OrderPage/OrderPage";
import CreateUserPage from "@pages/CreateUserPage/CreateUserPage";
import UsersListPage from "@pages/UsersListPage/UsersListPage";
import CreatePartsPage from "@pages/CreatePartsPage/CreatePartsPage";
import PartsListPage from "@pages/PartsListPage/PartsListPage";
import PartPage from "@pages/PartPage/PartPage";

export const publicPaths: RouteObject[] = [
  {
    path: "/login",
    element: <CenterLayout />,
    children: [
      {
        path: "/login",
        element: <LoginPage />
      }
    ]
  }
];

export const privatePaths: RouteObject[] = [
  {
    path: "/",
    element: <PanelLayout />,
    children: [
      {
        path: "/",
        element: <IndexPage /> 
      },
      {
        path: "/parts/create",
        element: <CreatePartsPage />
      },
      {
        path: "/parts/list",
        element: <PartsListPage />
      },
      {
        path: "/parts/id/:id",
        element: <PartPage />
      },
      {
        path: "/order/create",
        element: <CreateOrderPage /> 
      },
      {
        path: "/order/list",
        element: <OrderListPage />
      },
      {
        path: "/order/base",
        element: <OrderBasePage />
      },
      {
        path: "/order/id/:id",
        element: <OrderPage />
      }
    ]
  }
];

export const adminPaths: RouteObject[] = [
  {
    path: "/",
    element: <PanelLayout />,
    children: [
      {
        path: "/users/create",
        element: <CreateUserPage />
      },
      {
        path: "/users/list",
        element: <UsersListPage /> 
      }
    ]
  }
];