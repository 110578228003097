import { FC } from "react";

import "./Avatar.scss";

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  type: "image" | "initials",
  content: string
}

const Avatar: FC<AvatarProps> = ({type, content, ...props}) => {
  return (
    <div {...props} className={["avatar", props.className].join(" ")}>
      {
        type === "image"
        ? <img src={content} alt="" />
        : <b>{content}</b>
      }
    </div>
  );
};

export default Avatar;