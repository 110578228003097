import { FC, useState, useEffect, useRef } from "react";

import Block from "@components/common/Block/Block";
import SelectMenu from "@components/common/SelectMenu/SelectMenu";
import DropDown, { DropDownContent } from "@components/common/DropDown/DropDown";

import "./FilterSearch.scss";

interface filter {
  id: string,
  name: string,
  values: {
    name: string,
    value: string
  }[]
}

interface FilterSearchProps {
  onChange: (value: any) => void,
  filters: filter[]
}

function parseFilters(filters: filter[]): any {
  const parsed: {
    [key: string]: any
  } = {};

  filters.forEach((filter) => {
    parsed[filter.id] = {
      name: filter.name,
      value: filter.values[0],
      values: filter.values
    }
  })

  return parsed;
}

function parseFiltersToQuery(filters: filter[], state: any): any {
  const parsed: any = {};

  filters.forEach((filter) => {
    parsed[filter.id] = state[filter.id].value.value
  })

  return parsed;
}

const FilterSearch: FC<FilterSearchProps> = ({ onChange, filters }) => {
  const timer = useRef<any>(null);

  const [orderState, setOrderState] = useState<any>({
    name: "",
    ...parseFilters(filters)
  });

  parseFilters(filters);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      onChange({name: orderState.name, ...parseFiltersToQuery(filters, orderState)});
    }, 300)
    return () => {
      clearInterval(timer.current);
    }
  }, [orderState]); // eslint-disable-line

  return (
    <Block className="order_search">
      <input placeholder="Поиск" type="text" value={orderState.name} onChange={(e: any) => {setOrderState({...orderState, name: e.target.value})}} />
      <div className="order_search__filters">
        {
          filters.map((filter: filter, index) => 
            <div key={index} className="order_search__filter">
              <span>{filter.name}</span>
              <DropDown>
                <button className="order_search__select-btn style--accent-lite">{orderState[filter.id].value.name}</button>
                <DropDownContent>
                  <Block style={{marginBottom: "0px"}}>
                    <SelectMenu btns={filter.values} value={orderState[filter.id].value.value} onChange={(value) => setOrderState({
                      ...orderState, 
                      [filter.id]: {...orderState[filter.id], value}
                    })} />
                  </Block>
                </DropDownContent>
              </DropDown>
            </div>
          )
        }
      </div>
    </Block>
  );
};

export default FilterSearch;