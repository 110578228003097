import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useFetching } from "@hooks/useFetch";

import UserService from "@api/UserService";

import Block from "@components/common/Block/Block";
import SwitchButton from "@components/common/SwitchButton/SwitchButton";
import ActionForm, { ActionFormRow, ActionFormInput, ActionFormButton } from "@components/common/ActionForm/ActionForm";

const CreateUserPage: FC = () => {
  const router = useNavigate();

  const [user, setUser] = useState({
    login: "",
    email: "",
    firstName: "",
    lastName: "",
    secondName: "",
    password: "",
    re_password: "",
    isAdmin: false
  });

  const [createUserFetching] = useFetching(async () => {
    const response = await UserService.admin.register(user);
    if (response.data.status) {
      router("/users/list")
    }
  });

  function createUser() {
    if (user.password !== user.re_password) {
      return;
    }

    createUserFetching();
  }

  function updateUser(key: string, value: string | boolean) {
    setUser({ ...user, [key]: value });
  }

  return (
    <section className="content">
      <Helmet>
        <title>Создание пользователя - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Block title="Добавить пользователя" >
        <ActionForm>
          <ActionFormInput
            title="Логин"
            actionProps={{
              name:"login",
              type:"text",
              autoComplete: "off",
              value: user.login,
              onChange: (e: any) => updateUser(e.target.name, e.target.value)
            }}
          />
          <ActionFormRow>
            <ActionFormInput
              title="Имя"
              actionProps={{
                name:"firstName",
                type:"text",
                value: user.firstName,
                onChange: (e: any) => updateUser(e.target.name, e.target.value)
              }}
            />
            <ActionFormInput
              title="Фамилия"
              actionProps={{
                name:"lastName",
                type:"text",
                value: user.lastName,
                onChange: (e: any) => updateUser(e.target.name, e.target.value)
              }}
            /> 
            <ActionFormInput
              title="Отчество"
              actionProps={{
                name:"secondName",
                type:"text",
                value: user.secondName,
                onChange: (e: any) => updateUser(e.target.name, e.target.value)
              }}
            /> 
          </ActionFormRow>
          <ActionFormInput
            title="Почта"
            actionProps={{
              name:"email",
              type:"email",
              autoComplete: "off",
              value: user.email,
              onChange: (e: any) => updateUser(e.target.name, e.target.value)
            }}
          />
          <ActionFormInput
            title="Пароль"
            actionProps={{
              name:"password",
              type:"password",
              autoComplete: "new-password",
              value: user.password,
              onChange: (e: any) => updateUser(e.target.name, e.target.value)
            }}
          /> 
          <ActionFormInput
            title="Повторите пароль"
            actionProps={{
              name:"re_password",
              type:"password",
              autoComplete: "new-password",
              value: user.re_password,
              onChange: (e: any) => updateUser(e.target.name, e.target.value)
            }}
          /> 
          <ActionFormRow>
            <span>Права администратора</span>
            <SwitchButton value={user.isAdmin} changeValue={(value) => updateUser("isAdmin", value)} />
          </ActionFormRow>
          {
            user.isAdmin &&
            <span style={{fontSize: "80%", color: "var(--color-accent)"}}>Права администратора дают возможность управлять пользователями и другими данными. Не создавайте аккаунт администратора без необходимости</span>
          }
          <ActionFormRow direction="end">
            <ActionFormButton
              text="Создать"
              actionProps={{
                className: "style--positive",
                onClick: () => createUser()
              }}
            />
          </ActionFormRow>
        </ActionForm>
      </Block>
    </section>
  );
};

export default CreateUserPage;