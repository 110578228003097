import { FC } from "react";
import { useNavigate } from "react-router-dom";

import Block from "@components/common/Block/Block";

import "./PartPreview.scss";

interface PartPreviewProps {
  data: {
    id: string,
    name: string,
    brand: string,
    vendor: string,
    amount: number,
    strict: boolean,
    price_purchasing: number,
    price_retail: number
  }
}

const PartPreview: FC<PartPreviewProps> = ({ data }) => {
  const router = useNavigate();

  return (
    <Block onClick={() => {router(`/parts/id/${data.id}`)}} className="part-preview">
      <div className="part-preview__header">
        <div className="part-preview__title">
          <strong>{data.name}</strong>
          <span>{data.vendor}</span>
        </div>
        <div className="part-preview__amount">
          {
            data.strict
            ? <span className={data.amount <= 3 ? "style--negative-lite" : "style--positive-lite"}>{data.amount} шт.</span>
            : <span className="style--freeze-lite">Без учёта</span>
          }
        </div>
      </div>
      <div className="part-preview__tags">
        <div className="part-preview__tag style--freeze">
          <span>Закупочная цена</span>
          <strong>{data.price_purchasing} ₽</strong>
        </div>
        <div className="part-preview__tag style--freeze">
          <span>Розничная цена</span>
          <strong>{data.price_retail} ₽</strong>
        </div>
        <div className="part-preview__tag style--freeze">
          <span>Бренд</span>
          <strong>{data.brand}</strong>
        </div>
        {
          (data.strict && data.amount <= 3) && (
            <div className="part-preview__tag style--translucent-15-negative">
              <span>В наличии</span>
              <strong>{data.amount} шт.</strong>
            </div>
          )
        }
      </div>
    </Block>
  );
};

export default PartPreview;