import React, { FC, useState, useRef } from "react";

import { useClickOver } from "@hooks/useClickOver";

import "./dropdown.scss";

interface dropDownProps {
  [key: string]: any
  hoverable?: boolean
  className?: string
  children?: React.ReactNode
}

interface dropDownContentProps {
  [key: string]: any
  className?: string
  children?: React.ReactNode
}

const DropDown: FC<dropDownProps> = ({children, className, hoverable=false, ...props}) => {
  const [open, setOpen] = useState(false);

  const rootElement = useRef(null);
  const rootClasses = ["dropdown"];
  
  if (className) rootClasses.push(className);
  if (hoverable) rootClasses.push("dropdown--hoverable");
  if (open) rootClasses.push("dropdown--open");

  useClickOver(rootElement, () => {
    setOpen(false);
  });

  return (
    <div ref={rootElement} {...props} onClick={() => setOpen(true)} className={rootClasses.join(' ')}>
      {children}
    </div>
  );
}

export const DropDownContent: FC<dropDownContentProps> = ({children, className, ...props}) => {
  return (
    <div {...props} className={["dropdown__content", className].join(" ")}>
      {children}
    </div>
  );
}

export default DropDown;