import AxiosInstance from "@lib/axiosInstanse";

export default class PartService {
  public static async getParts(query: any) {
    return await AxiosInstance.get("/parts/get", {params: {query: query}});
  }
  public static async getCountParts(query: any) {
    return await AxiosInstance.get("/parts/count", {params: {query: query}});
  }
  public static async getPartById(id: string) {
    return await AxiosInstance.get(`/parts/get/${id}`);
  }
  public static async createPart(PartData: any) {
    return await AxiosInstance.post("/parts/create", PartData);
  }
  public static async updatePart(id: string, partData: any) {
    return await AxiosInstance.put(`/parts/update`, {id, data: partData});
  }
  public static async deletePart(id: string) {
    return await AxiosInstance.delete(`/parts/delete/${id}`);
  }
}