import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";

import MainContext from "@contexts/MainContext";
import AuthContext from "@contexts/AuthContext";

import Avatar from "@components/common/Avatar/Avatar";
import Block from "@components/common/Block/Block";
import DropDown, {DropDownContent} from "@components/common/DropDown/DropDown";

import "./UserMenu.scss";

const UserMenu: FC = () => {
  const router = useNavigate();

  const mainContext = useContext(MainContext);
  const authContext = useContext(AuthContext);

  return (
    <div className="user-menu">
      <DropDown>
        <Avatar style={{cursor: "pointer"}} type="initials" content={authContext.user.firstName[0].toUpperCase()} />
        <DropDownContent>
          <Block className="user-menu__content">
            <Avatar className="user-menu__avatar" type="initials" content={authContext.user.firstName[0].toUpperCase()} />
            <span className="style--freeze-lite">@{authContext.user.login}</span>
            <div className="user-menu__name">
              <b>{authContext.user.firstName}</b>
              <b>{authContext.user.lastName}</b>
            </div>
            <div className="user-menu__tags">
              <button onClick={() => router("/order/list")} className={`user-menu__tag ${authContext.user.activeOrders === 0 ? "style--freeze" : "style--translucent-15-positive"}`}>
                <span>Активные заказы</span>
                <strong>{authContext.user.activeOrders}</strong>
              </button>
              <button onClick={() => router("/")} className={`user-menu__tag ${mainContext.warns === 0 ? "style--freeze" : "style--translucent-15-warning"}`}>
                <span>Предупреждения</span>
                <strong>{mainContext.warns}</strong>
              </button>
            </div>
            <button className="style--negative" onClick={() => authContext.logout()}>Выйти</button>
          </Block>
        </DropDownContent>
      </DropDown>
    </div>
  );
};

export default UserMenu;