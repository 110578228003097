import { FC, useContext } from "react";

import AuthContext from "@contexts/AuthContext";

import Block from "../common/Block/Block";

import GroupIcon from "../icons/GroupIcon";

import "./UserPreview.scss";

interface user {
  id: string,
  login: string
  firstName: string
  lastName: string
  secondName: string
  email: string
  isAdmin: boolean
  isActivated: boolean
}

interface userPreviewProps {
  user: user
  onUserChange: (user: user) => void
  onUserChangePassword: (user: user) => void
  onUserDelete: (user: user) => void
}

const UserPreview: FC<userPreviewProps> = ({user, onUserChange, onUserChangePassword, onUserDelete}) => {
  const authContext = useContext(AuthContext);

  return (
    <Block className="user_preview">
      <div className="user_preview__header">
        {
          user.isAdmin
          ? <GroupIcon className="style--fill-accent"/>
          : <GroupIcon/>
        }
        <strong>{user.firstName} {user.lastName} {user.secondName}</strong>
        {
          user.id === authContext.user.id &&
          <span style={{fontSize: "80%"}} className="style--freeze-lite">это ваш аккаунт</span>
        }
      </div>
      <div className="user_preview__content user_preview--active">
        <div className="user_preview__row">
          <span>Логин: {user.login}</span>
        </div>
        <div className="user_preview__row">
          <span>Почта: {user.email}</span>
        </div>
        <div onClick={(e) => e.stopPropagation()} className="user_preview__btns">
          <button onClick={() => onUserChange(user)} className="style--positive">Редактировать</button>
          <button onClick={() => onUserChangePassword(user)} className="style--positive">Сменить пароль</button>
          {
            user.id !== authContext.user.id &&
            <button onClick={() => onUserDelete(user)} className="style--negative">Удалить</button>
          }
        </div>
      </div>
    </Block>
  );
};

export default UserPreview;