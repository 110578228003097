import { FC, useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { useFetching } from "@hooks/useFetch";

import UserService from "@api/UserService";

import UserPreview from "@components/UserPreview/UserPreview";
import Drawer from "@components/common/Drawer/Drawer";
import ActionForm, { ActionFormButton, ActionFormInput, ActionFormRow } from "@components/common/ActionForm/ActionForm";
import Dialog from "@components/common/Dialog/Dialog";

const UsersListPage: FC = () => {

  const [users, setUsers] = useState([]);
  const [targetUser, setTargetUser] = useState<any>({
    login: "",
    firstName: "",
    lastName: "",
    secondName: "",
    email: "",
  });
  const [targetUserPassword, setTargetUserPassword] = useState<any>({
    password: "",
    re_password: ""
  });

  const [userChangeOpen, setUserChangeOpen] = useState(false);
  const [userChangePasswordOpen, setOnUserChangePasswordOpen] = useState(false);
  const [userDeleteOpen, setUserDeleteOpen] = useState(false);

  const [fetchUsers] = useFetching(async () => {
    const response = await UserService.getUsers();
    if (response.data.status) {
      setUsers(response.data.response);
    } 
  })

  const [fecthUpdateUser] = useFetching(async () => {
    const response = await UserService.admin.updateUser(targetUser.id, targetUser);
    if (response.data.status) {
      setUserChangeOpen(false);
      fetchUsers();
    }
  })

  const [fecthUpdateUserPassword] = useFetching(async () => {
    const response = await UserService.admin.updatePassword(targetUser.id, targetUserPassword.password);
    if (response.data.status) {
      setTargetUserPassword({
        password: "",
        re_password: ""
      });
      setOnUserChangePasswordOpen(false);
      fetchUsers();
    }
  })

  const [fetchDeleteUser] = useFetching(async (id: string) => {
    const response = await UserService.admin.deleteUser(id);
    if (response.data.status) {
      fetchUsers();
      setUserDeleteOpen(false);
    }
  })

  function updateUser() {
    fecthUpdateUser();
  }

  function changeUserPassword() {
    if (targetUserPassword.password === targetUserPassword.re_password) {
      fecthUpdateUserPassword();
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []) // eslint-disable-line

  return (
    <section className="content">
      <Helmet>
        <title>Пользователи - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      {
        users.map((user, index) =>
          <UserPreview 
            key={index} 
            user={user}
            onUserChange={(user) => {setTargetUser(user); setUserChangeOpen(!userChangeOpen)}}
            onUserChangePassword={(user) => {setTargetUser(user); setOnUserChangePasswordOpen(!userChangePasswordOpen)}}
            onUserDelete={(user) => {setTargetUser(user); setUserDeleteOpen(!userDeleteOpen)}}
          />
        )
      }
      <Drawer title="Редактировать пользователя" state={userChangeOpen} onClose={() => setUserChangeOpen(false)}>
        <ActionForm>
          <ActionFormRow>
            <ActionFormInput actionProps={{
              type: "text", 
              value: targetUser.login,
              onChange: (e: any) => {
                setTargetUser({
                  ...targetUser,
                  login: e.target.value
                })
              }
            }} title="Логин" />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormInput actionProps={{
              type: "text", 
              value: targetUser.firstName,
              onChange: (e: any) => {
                setTargetUser({
                  ...targetUser,
                  firstName: e.target.value
                })
              }
            }} title="Имя" />
            <ActionFormInput actionProps={{
              type: "text", 
              value: targetUser.lastName,
              onChange: (e: any) => {
                setTargetUser({
                  ...targetUser,
                  lastName: e.target.value
                })
              }
            }} title="Отчество" />
            <ActionFormInput actionProps={{
              type: "text", 
              value: targetUser.secondName,
              onChange: (e: any) => {
                setTargetUser({
                  ...targetUser,
                  secondName: e.target.value
                })
              }
            }} title="Фамилия" />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormInput actionProps={{
              type: "text", 
              value: targetUser.email,
              onChange: (e: any) => {
                setTargetUser({
                  ...targetUser,
                  email: e.target.value
                })
              }
            }} title="Почта" />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormButton actionProps={{className: "style--positive", onClick: () => updateUser()}} text="Изменить" />
          </ActionFormRow>
        </ActionForm>
      </Drawer>
      <Drawer title="Изменить пароль пользователя" state={userChangePasswordOpen} onClose={() => setOnUserChangePasswordOpen(false)}>
        <ActionForm>
          <ActionFormRow>
            <ActionFormInput actionProps={{
              type: "password",
              value: targetUserPassword.password,
              onChange: (e: any) => {
                setTargetUserPassword({
                  ...targetUserPassword,
                  password: e.target.value
                })
              }
            }} title="Новый пароль" />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormInput actionProps={{
              type: "password",
              value: targetUserPassword.re_password,
              onChange: (e: any) => {
                setTargetUserPassword({
                  ...targetUserPassword,
                  re_password: e.target.value
                })
              }
            }} title="Повторите новый пароль" />
          </ActionFormRow>
          <ActionFormRow>
            <ActionFormButton actionProps={{className: "style--positive", onClick: () => changeUserPassword()}} text="Изменить" />
          </ActionFormRow>
        </ActionForm>
      </Drawer>
      <Drawer title="Удалить пользователя" state={userDeleteOpen} onClose={() => setUserDeleteOpen(false)}>
        <div className="center">
          <Dialog 
            title="Вы точно хотите удалить пользователя?" 
            subtitle="Все выполненные заявки этим пользователем сохранят имя и фамилию."
            onSuccess={() => fetchDeleteUser(targetUser.id)}
            onCancel={() => setUserDeleteOpen(false)}
          />
        </div>
      </Drawer>
    </section>
  );
};

export default UsersListPage;