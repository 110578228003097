import { FC, useState } from "react";
import { Helmet } from "react-helmet";

import { useFetching } from "@hooks/useFetch";

import PartService from "@api/PartService";

import FilterSearch from "@components/FilterSearch/FilterSearch";
import PartPreview from "@components/PartPreview/PartPreview";

const PartsListPage: FC = () => {
  const [parts, setParts] = useState<any[]>([]);

  const [fetchParts] = useFetching(async (query: any) => {
    const response = await PartService.getParts(query);
    if (response.data.status) {
      setParts(response.data.response);
    }
  })

  function onSearchChange(query: any) {
    fetchParts(query);
  }

  return (
    <section className="content">
      <Helmet>
        <title>Запчасти - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <FilterSearch onChange={onSearchChange} filters={[
        {
          id: "sort",
          name: "Сортировать по",
          values: [
            {
              value: "name",
              name: "имени"
            },
            {
              value: "amount",
              name: "количеству"
            }
          ]
        }
      ]}/>
      {
        parts.length !== 0
        ?
        parts.map((part, index) =>
          <PartPreview key={index} data={part} />
        )
        : <div style={{width: "100%", padding: "20px", textAlign: "center"}}>Ничего не найдено</div>
      }
    </section>
  );
};

export default PartsListPage;