import { FC } from "react";

import "./SwitchButton.scss";

interface SwitchButtonProps extends React.HtmlHTMLAttributes<HTMLLabelElement> {
  value: boolean,
  changeValue: (value: boolean) => void
}

const SwitchButton: FC<SwitchButtonProps> = ({ changeValue, value, ...props }) => {
  return (
    <label {...props} className={["switch-button", props.className].join(" ")}>
      <input onChange={() => changeValue(!value)} checked={value} type="checkbox"/>
      <span className="switch-button__slider"></span>
    </label>
  );
};

export default SwitchButton;
