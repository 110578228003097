import { FC, useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import MainContext from "@contexts/MainContext";

import { useFetching } from "@hooks/useFetch";

import OrderService from "@api/OrderService";
import PartService from "@api/PartService";

import OrderPreview from "@components/OrderPreview/OrderPreview";
import PartPreview from "@components/PartPreview/PartPreview";
import Block from "@components/common/Block/Block";

import "./IndexPage.scss";

const IndexPage: FC = () => {
  const mainContext = useContext(MainContext);

  const router = useNavigate();

  const [warnOrders, setWarnOrders] = useState<any[]>([]);
  const [warnParts, setWarnParts] = useState<any[]>([]);
  const [countActiveOrders, setCountActiveOrders] = useState(0);
  const [countOrders, setCountOrders] = useState(0);
  const [countParts, setCountParts] = useState(0);
  const [countWarnParts, setCountWarnParts] = useState(0);

  const [fetchWarnOrders] = useFetching(async (query: any) => {
    const response = await OrderService.getOrders(query);
    if (response.data.status) {
      setWarnOrders(response.data.response);
    }
  })

  const [fetchWarnParts] = useFetching(async (query: any) => {
    const response = await PartService.getParts(query);
    if (response.data.status) {
      setWarnParts(response.data.response);
    }
  })

  const [fatchCountOrders] = useFetching(async (query: any, callback: any) => {
    const response = await OrderService.getCountOrders(query);
    if (response.data.status) {
      callback(response.data.response);
    }
  })

  const [fatchCountParts] = useFetching(async (query: any, callback: any) => {
    const response = await PartService.getCountParts(query);
    if (response.data.status) {
      callback(response.data.response);
    }
  })

  useEffect(() => {
    fatchCountOrders({query: {}}, setCountOrders);
    fatchCountOrders({query: {status: "in_progress"}}, setCountActiveOrders);

    fatchCountParts({}, setCountParts);
    fatchCountParts({strict: true, amount: { $lte: 3 } }, setCountWarnParts);

    fetchWarnOrders({
      query: {
        status: {
          $in: ["in_progress", "added"]
        },
        expiration_date: {
          $lte: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      }
    });

    fetchWarnParts({
      strict: true,
      amount: {
        $lte: 3
      }
    });
  }, []) // eslint-disable-line

  useEffect(() => {
    mainContext.setWarns(warnOrders.length + warnParts.length);
  }, [warnOrders, warnParts]) // eslint-disable-line

  return (
    <section className="content">
      <Helmet>
        <title>Главная - {process.env.REACT_APP_NAME}</title>
      </Helmet>
      <Block className="index-data">
        <div className="index-data__tags">
          <button onClick={() => router("/order/list")} className={`index-data__tag index-data__tag--flex ${countActiveOrders === 0 ? "style--freeze" : "style--translucent-15-positive"}`}>
            <span>Заявки в работе</span>
            <strong>{countActiveOrders}</strong>
          </button>
          <button onClick={() => router("/order/base")} className="index-data__tag index-data__tag--flex style--freeze">
            <span>Всего заявок</span>
            <strong>{countOrders}</strong>
          </button>
        </div>
        <div className="index-data__tags">
          <button onClick={() => router("/parts/list")} className={`index-data__tag index-data__tag--flex style--freeze ${countWarnParts === 0 ? "style--freeze" : "style--translucent-15-warning"}`}>
            <span>Запчасти требующие внимания</span>
            <strong>{countWarnParts}</strong>
          </button>
          <button onClick={() => router("/parts/list")} className="index-data__tag index-data__tag--flex style--freeze">
            <span>Вcего запчастей</span>
            <strong>{countParts}</strong>
          </button>
        </div>
      </Block>
      <div className="index-warns">
        {
          warnOrders.length !== 0 && (
            <>
              <strong className="index-warns__header">Заявки требующие внимания - {warnOrders.length}</strong>
              {
                warnOrders.map((item, index) => 
                  <OrderPreview key={index} order={item} />
                )
              }
            </>
          )
        }
        {
          warnParts.length !== 0 && (
            <>
              <strong className="index-warns__header">Запчасти требующие внимания - {warnParts.length}</strong>
              {
                warnParts.map((item, index) => 
                  <PartPreview key={index} data={item} />
                )
              }
            </>
          )
        }
      </div>
    </section>
  );
};

export default IndexPage;